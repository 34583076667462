<template>
 <v-container>
  <infocard />
  <heartrate />
  <linechart />
  <timeline />
</v-container>
</template>

<script>
  import infocard from '../components/infocard'
  import heartrate from '../components/heartrate'
  import linechart from '../components/linechart'
  import timeline from '../components/Timeline'
  export default {
    name: 'Home',

    components: {
      infocard,
      heartrate,
      linechart,
      timeline
      
    },
  }
</script>

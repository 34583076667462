<template>
  <v-card
    class="mx-auto my-5"
    
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          Super Hospital - OICU - Obstetrics and Gynecology Department
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Xiao Hong
        </v-list-item-title>
        <v-list-item-subtitle>Patient information: This is a demo patient.</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="grey"
      ></v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
    <v-chip-group column>
          <v-chip
      class="ma-2"
      color="success"
      outlined
    >
      <v-icon left>
        mdi-checkbox-marked-circle
      </v-icon>
      Status: Stable
    </v-chip>
    <v-chip
      class="ma-2"
      color="primary"
      outlined
    >
<v-icon left>
        mdi-clipboard-text-clock
      </v-icon>
        2 Days in ICU

    </v-chip>
    <v-chip
      class="ma-2"
      color="deep-purple accent-4"
      outlined
    >
      <v-icon left>
        mdi-account-outline
      </v-icon>
      Age: 26
    </v-chip>

     <v-chip
      class="ma-2"
      color="deep-orange accent-4"
      outlined
    >
      <v-icon left>
        mdi-weight-kilogram
      </v-icon>
      Body weight: 59 KG
    </v-chip>
    <v-chip
      class="ma-2"
      color="indigo darken-3"
      outlined
    >
      <v-icon left>
        mdi-gender-female
      </v-icon>
      Female
    </v-chip>
    <v-chip
      class="ma-2"
      color="black"
      outlined
    >
      <v-icon left>
        mdi-map-marker-outline
      </v-icon>
      No. 2 Bed. OICU.
    </v-chip>
    </v-chip-group>

    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'infocard',
  }
</script>
